import React from 'react';
import './css/KingyoMessageTop.css';

export default function KingyoMessageTop() {
    return (
        <div className="row">
            <div className="col-md-12 text-center topmessage">
                <span>元気な 金魚 を中心に、</span>
                <span>メダカ</span>・<span>熱帯魚</span>・<span>水草</span>・<span>飼育器具を</span>
                <span>取り揃えています！</span>
            </div>
            <div className="col-md-12 text-center sukui">
                <strong><span>すくい金魚</span>&nbsp;</strong>
                <span>&nbsp;取扱店</span><span>（要予約）</span>
                <span>
                    <img src="/images/sukui.gif" width="auto" height="16" alt="すくい" />
                    <img src="/images/rkin.gif" width="auto" height="32" alt="金魚" />
                </span>
            </div>
        </div>
    );
}
