import React from 'react';
import './css/KingyoOpen.css';

export default function KingyoOpen() {
    return (
        <div className="row">
            <div className="col-md-12 text-center">
                <div>
                    <div className="open-caption">営業時間</div>
                    <div>10:00 AM ～ 6:30 PM</div>
                </div>
                <div>
                    <div className="open-caption">定休日</div>
                    <div>毎週 月曜日</div>
                    <div>※1〜3月は 火曜日も定休日
                    </div>
                </div>
            </div>
        </div>
    );
}
