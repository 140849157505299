import React from 'react';
import './css/KingyoBuildingPhoto.css';

export default function KingyoBuildingPhoto() {
    return (
        <div className="row">
            <div className="col-xs-12">
                <img className="img-building" src="/images/house.jpg" alt="外観" loading="lazy" />
            </div>
        </div>
    );
}
