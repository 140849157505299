import React from 'react';

export default function KingyoFooter() {
    return (
        <footer className="text-center">
            <div className="row">
                <div className="col-xs-12">
                    <p>Copyright © アクアプラン金魚館. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}
