import React from 'react';
import KingyoHeader from './KingyoHeader.js';
import KingyoMessageTop from './KingyoMessageTop';
import KingyoPhotos from './KingyoPhotos';
import KingyoContant from './KingyoContant';
import KingyoOpen from './KingyoOpen';
import KingyoMapSimple from './KingyoMapSimple';
import KingyoMapGoogle from './KingyoMapGoogle';
import KingyoBuildingPhoto from './KingyoBuildingPhoto'
import KingyoFooter from './KingyoFooter';
import './css/KingyokanTop.css';

const versionText = {
    fontSize: '8px',
    color: '#def',
    textAlign: 'right',
};

export default function KingyokanTop() {
    return (
        <div>
            <KingyoHeader />
            <KingyoMessageTop />
            <KingyoPhotos />
            <KingyoContant />
            <KingyoOpen />
            <KingyoMapSimple />
            <KingyoMapGoogle />
            <KingyoBuildingPhoto />
            <KingyoFooter />
            <div style={versionText}>
                version 2022.04.17
            </div>
        </div>
    );
}
