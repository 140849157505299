import React from 'react';

export default function Counter() {
    const today = new Date();
    return (
        <table>
            <tr>
                <td>Date</td>
                <td style={{paddingLeft: '2rem'}}>Count</td>
            </tr>
            <tr>
                <td>2022/3/13 </td>
                <td style={{paddingLeft: '2rem'}}>6,522</td>
            </tr>
            <tr>
                <td>{`${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`}</td>
                <td style={{paddingLeft: '2rem'}}>計測中...</td>
            </tr>
        </table>
    );
}
