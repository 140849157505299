import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import KingyokanTop from './KingyokanTop';
import Counter from './Counter';
import axios from 'axios';

const API = process.env.REACT_APP_API || 'api';

export default function App() {

  useEffect(async () => {
    await axios.post(`${API}/products-C0CD8D41-1DA0-4FF9-A3FD-4DCAA4666DC4`);
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        <KingyokanTop />
      </Route>
      <Route exact path="/count.txt">
        <Counter />
      </Route>
    </Switch>
  );
}
