import React from 'react';
import './css/KingyoHeader.css';
import imgsea from './res/bgd.jpg';

export default function KingyoHeader() {
    return (
        <div className="row" style={{ backgroundImage: `url(${imgsea})`}}>
            <div className="col-sm-12">
                <img className="img-header" src="images/logo3.png" alt="アクアプラン金魚館"/>
            </div>
        </div>
    );
}
