import React from 'react';
import './css/KingyoMapSimple.css';

export default function KingyoMapSimple() {
    return (
        <div className="row">
            <div className="col-12 img-simple-map">
                <img src="/images/smap.png" width="100%" height="auto" alt="地図" loading="lazy" />
            </div>
        </div>
    );
}
