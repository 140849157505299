import React from 'react';
import './css/KingyoContant.css';

export default function KingyoContant() {
    return (
        <div className="row">
            <div className="col-md-12 text-center">
                <h1>お問い合わせ</h1>
                <div className="text-center">
                    <strong>アクアプラン金魚館</strong>
                </div>
                <div>
                    〒880-0031
                </div>
                <div>
                    宮崎県 宮崎市 船塚3-19
                </div>
                <div className="contact-space">
                    <abbr title="Phone">電話：</abbr>
                    <a href="tel:0985248474"> (0985) 24-8474</a>
                </div>
            </div>
        </div>
    );
}
