import React from 'react';
import './css/KingyoPhotos.css';

export default function KingyoPhotos() {
    return (
        <div className="row">
            <div className="col-sm-6"> 
                <img src="/images/fish05.jpg" className="img-photos center-block" alt="写真1" loading="lazy" />
            </div>
            <div className="col-sm-6"> 
                <img src="/images/fish01.jpg" className="img-photos center-block" alt="写真2" loading="lazy" />
            </div>
            <div className="col-sm-6"> 
                <img src="/images/fish02.jpg" className="img-photos center-block" alt="写真3" loading="lazy" />
            </div>
            <div className="col-sm-6"> 
                <img src="/images/fish03.jpg" className="img-photos center-block" alt="写真4" loading="lazy" />
            </div>
        </div>
    );
}
