import React from 'react';

export default function KingyoMapGoogle() {
    return (
        <div className="row">
            <div className="col-md-12">
                <iframe width="99%" height="360px" title="map" loading="lazy" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27087.4915489652!2d131.3941059598368!3d31.935482849807542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3538b7360ae2c9dd%3A0x797d2bbe4f0d745b!2z44Ki44Kv44Ki44OX44Op44Oz6YeR6a2a6aSo!5e0!3m2!1sja!2sjp!4v1483944940100" />
            </div>
        </div>
    );
}
